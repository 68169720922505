import React from 'react'
import LayoutConstant from '@lib/constants/layout.constant'
import { PagePathConstant } from '@lib/constants'

function GlobalLoginPage() {
  return <></>
}

GlobalLoginPage.pageMeta = {
  metaTitle: 'Login',
  metaDescription: 'Global login',
  metaKeywords: '',
  path: PagePathConstant.GLOBAL,
  layout: LayoutConstant.Empty,
}

export default GlobalLoginPage
